import { useState } from 'react';
import logo from './logo.gif';
import poster from './poster.png';

function App() {
  const [visible, setVisible] = useState(false);

  function openSidebar() {
    setVisible(true);
  }

  function closeSidebar() {
    setVisible(false);
  }

  return (
    <div className="App">
      <div id="sidebar" style={{visibility: visible ? 'visible' : 'hidden' }}>
        <div className="sidemenu">
              <button onClick={closeSidebar} className="close-sidebar">
                  <span></span>
                  <span></span>
              </button>
              <ul>
                  <li className="log"><a href="https://rebrand.ly/skh2s8h" target="_blank" rel="noopener nofollow noreferrer"><strong><i
                                  className="fa-solid fa-right-to-bracket"></i> LOGIN</strong></a></li>
                  <li className="reg"><a href="https://rebrand.ly/skh2s8h?pg=register" target="_blank"
                          rel="noopener nofollow noreferrer"><strong><i className="fas fa-user-plus"></i> DAFTAR</strong></a></li>
              </ul>
          </div>
      </div>
      <header>
          <nav>
              <div className="container">
                  <div className="brand">
                      <img className="logo" height="65" width="143" alt="Jateng Toto"
                          src={logo} />
                  </div>
                  <div className="menu">
                      <a href="https://rebrand.ly/skh2s8h" className="btn" target="_blank"
                          rel="noopener nofollow noreferrer">LOGIN</a>&nbsp;
                      <a href="https://rebrand.ly/skh2s8h?pg=register" className="btn-reg"
                          target="_blank" rel="noopener nofollow noreferrer">DAFTAR</a>
                  </div>
                  <div className="menu-mobile"><button onClick={openSidebar}>☰</button></div>
              </div>
          </nav>
      </header>
      <main>
        <section className="hero">
            <div className="row">
                <div className="col-lg-6 col-md-12">
                    <img decoding="async" className="imghero" alt="Selamat Datang di JatengToto"
                        src={poster}></img>
                </div>
                <div className="col-lg-6 col-md-12 hrinfo">
                    <h1 className="hrbrand"><span className="b1">JATENGTOTO</span></h1>
                    <p className="hrjg">Daftar Platform Game Online Digital<br />Hadiah dan Diskon Terbesar! Menang Berapapun AUTO LUNAS!</p>
                    <div className="hrbtn">
                        <a href="https://rebrand.ly/skh2s8h?pg=register" className="btn1"
                            target="_blank" rel="noopener nofollow noreferrer"><i className="fas fa-user-plus"></i>
                            DAFTAR</a>
                        <a href="https://rebrand.ly/skh2s8h" className="btn2" target="_blank"
                            rel="noopener noreferrer nofollow"><i className="fa-solid fa-right-to-bracket"></i>
                            LOGIN</a>
                        <a href="https://heylink.me/LoginJatengtoto/" className="btn2" target="_blank"
                            rel="noopener noreferrer nofollow"><i className="fa-solid fa-right-to-bracket"></i> LINK ALTERNATIF</a>
                        <a href="https://direct.lc.chat/15892908/" className="btn2" target="_blank"
                            rel="noopener noreferrer nofollow"><i className="fa-solid fa-right-to-bracket"></i> HUBUNGI VIA CHAT</a>
                    </div>
                </div>
            </div>
          </section>
      </main>
    </div>
  );
}

export default App;
